@import '../../styles/variables/variables.scss';

.dashboard-card {
    box-shadow: 0 2px 4px 0 #00000033,
    0 3px 10px 0 $dark-grey;
    border-radius: 5px;
    padding: 2px;
}

.dashboard-card-frame {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.dashboard-card-frame-NoOverflow{
    width: 100%;
    height: 100%;
}

.showcase { 
    //overflow-x: scroll;
    overflow-y: hidden;
    height: auto;
    white-space:nowrap;
} 

.showcase-button {
    display:inline-block;
    width: 200px;
    margin-right: 5px;
}

//FOR CUSTOM SCROLLBAR
/* width */
::-webkit-scrollbar {
    width: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
   
/* Handle */
::-webkit-scrollbar-thumb {
    background: $primary-color;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background:$secondary-color;
}
@import './variables/variables.scss';

button {
    z-index: 0;
}

.primary-button {
    border-radius: 15px;
    background: $primary-color;
    box-shadow:  10px 10px 15px #8f999f48,
                -10px -10px 15px #c1cfd73f;
    border: none;
    padding: 5px 40px;
    text-align: center;
    display: inline-block;
    color: white;
    font-size: $button-font-size;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
       background: $disable-color;
       &:hover {
        background: $disable-color;
        cursor: not-allowed;
        }
    }
    min-width: 150px;
}

.primary-button:hover{
    background: $dark-primary-color;
}

.secondary-button {
    border-radius: 15px;
    background: $secondary-color;
    box-shadow:  10px 10px 15px #8f999f48,
                -10px -10px 15px #c1cfd73f;
    border: none;
    padding: 5px 40px;
    text-align: center;
    display: inline-block;
    color: white;
    font-size: $button-font-size;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
        background: $disable-color;
        &:hover {
            background: $disable-color;
            cursor: not-allowed;
        }
    }
    min-width: 150px;
}

.secondary-button:hover{
    background: $dark-secondary-color;
}

.active-button {
    border-radius: 15px;
    background: $active-color;
    box-shadow:  10px 10px 15px #8f999f48,
                -10px -10px 15px #c1cfd73f;
    border: none;
    padding: 5px 40px;
    text-align: center;
    display: inline-block;
    color: white;
    font-size: $button-font-size;
    font-weight: bold;
    cursor: pointer;
    min-width: 150px;
}

#login-button{
 width: 100%;
 border-radius: 15px;
 background: $active-color;
 box-shadow:  10px 10px 15px #8f999f48,
             -10px -10px 15px #c1cfd73f;
 border: none;
 padding: 5px 50px;
 text-align: center;
 display: inline-block;
 color: white;
 font-size: 20px;
 font-weight: bold;
 cursor: pointer;
}

.active-button:hover {
    background: $dark-active-color;
}

.disable-button {
    border-radius: 15px;
    background: $disable-color;
    box-shadow:  10px 10px 15px #8f999f48,
                -10px -10px 15px #c1cfd73f;
    border: none;
    padding: 5px 40px;
    text-align: center;
    display: inline-block;
    color: white;
    font-size: $button-font-size;
    font-weight: bold;
}


.search-button {
    border-radius: 10px;
    background: $primary-color;
    box-shadow:  10px 10px 15px #8f999f48,
                -10px -10px 15px #c1cfd73f;
    border: none;
    text-align: center;
    display: inline-block;
    color: white;
    font-size: $button-icon-size;
    font-weight: bold;
    cursor: pointer;
    margin-right: 10px;
    height: 35px;
    
    .anticon{
        svg{
            fill: #FFFFFF !important;
            }
    }
}

.search-button:hover{
    background: $dark-primary-color;
}

.ant-collapse {
  width: 100% !important;
  background-color: #fafafa00 !important;
}

.ant-collapse-content {
  background-color: rgba(255, 255, 255, 0) !important; 
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  text-align: left !important;
  font-size: 14px;
  font-weight: 600;
  background-color: $filter-header;
  color: #FFFFFF !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 10px !important;
}

.exit-button,
.exit-button:hover {
    font-size: 16px !important;
    color: $disable-color !important;
    background: #ffffff00;
    //background-image: linear-gradient(-90deg, #ffffff00 0%, rgba($primary-color, 0.5) 100%);
    border-radius: 10px;
    padding-left: 10px !important;
    float: right!important;
}

.link{
    color: $detail-color;
    font-size: 16px;

    @media only screen and (max-width: 48em) {
        font-size: 14px !important;
    }
}

.row-actions {
    text-align: center !important;
    padding-top: 10px;
    width: 100%;

    @media only screen and (max-width: 24em) {
        display: flex !important;
        flex-direction: column !important;
    }

    @media only screen and (max-width: 48em) {
        display: inline-block;
        text-align: center;
    }
}

.row-actions>button {
    margin: 5px;
    text-align: center !important;

    @media only screen and (max-width: 24em) {
        padding: 5px 50px !important;
    }

    @media only screen and (max-width: 48em) {
        display: inline-block;
        padding: 5px 30px !important;
    }
}

.row-actions-w {
    display: flex;
    justify-content: space-evenly;
    padding-top: 10px;

    @media only screen and (max-width: 24em) {
        display: flex !important;
        flex-direction: column !important;
    }
}

.row-actions-w>button {
    margin: 5px;
    text-align: center !important;

    @media only screen and (max-width: 24em) {
        padding: 5px 50px !important;
    }

    @media only screen and (max-width: 48em) {
        padding: 5px 30px !important;
    }
}

.row-actions-r {
    padding-top: 10px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
}

.row-actions-r>button {
    margin: 5px;
}

.row-actions-1 {
    display: flex;
    flex-direction: row-reverse;
}

@media only screen and (max-width: 48em) {
    .width-xs{
        min-width: 40px !important;
        padding: 5px 8%;
        text-align: center !important;
        margin-top: 10px;
        font-size: 14px;
    }

    .search-button {
        border-radius: 10px;
        background: $primary-color;
        box-shadow:  10px 10px 15px #8f999f48,
                    -10px -10px 15px #c1cfd73f;
        border: none;
        text-align: center;
        display: inline-block;
        color: white;
        font-size: $button-icon-size !important;
        font-weight: bold;
        cursor: pointer;
        margin-right: 10px;
        height: 35px;
        padding: 0 !important;
        margin-top: 0 !important;

        
        .anticon{
            svg{
                fill: #FFFFFF !important;
                }
        }
    }
}

